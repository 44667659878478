import { gql, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router'


const GET_STREAM_ACCESS = gql`
  query getStreamAccess($project: String!){
    getStreamAccess(project: $project)
  }
`;


export default function Guardien({project}) {
  const { loading, error, data, startPolling, stopPolling } = useQuery(GET_STREAM_ACCESS, {
    variables: { project: project },
  });



  useEffect(() => {
    startPolling(20000)
  return () => {
   stopPolling()
  }
}, [startPolling, stopPolling])


if(error){
  if(error.graphQLErrors[0]){
    console.log(error.graphQLErrors[0])
    return (<Redirect to="/closed"/>)

  }
}

  if(data){
    if(data.getStreamAccess === "early") return (<Redirect to="/closed"/>)
    if(data.getStreamAccess === "late") return (<Redirect to="/fin"/>)

  }
  return null
}

