import React, { useState, useEffect } from 'react';
import {useAuthDispatch} from '../../context/auth'



export default function Projects({project}) {
const authDispatch = useAuthDispatch()

  
    useEffect(() => {
      authDispatch({ type: 'LOGOUT' })
    
    }, []);
  

  

    return null
  

}
