import { gql, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

const GET_3QToken = gql`
query get3QToken($project: String!){
    get3QToken(project: $project){
      key, timestamp, dataId
    }
}`




export default function Projects({project}) {  


  const { loading, error, data } = useQuery(GET_3QToken, {
    variables: { project: project},

});

    useEffect(() => {
   if(data && data.get3QToken){

    if(window.js3qVideoPlayer){
      window.js3qVideoPlayer =  new window.js3q({
        'dataid': data.get3QToken.dataId,
        'container': 'myVideoPlayer',
        'sticky': true,
        'key' : data.get3QToken.key ,
        'timestamp' : data.get3QToken.timestamp
    }); 
    }else {
      var js3qVideoPlayer =  new window.js3q({
        'dataid': data.get3QToken.dataId,
        'container': 'myVideoPlayer',
        'sticky': true,
        'key' : data.get3QToken.key ,
        'timestamp' : data.get3QToken.timestamp
    }); 
    }
     
   }   
    }, [data]);
  
  
    if (error) {
      return (
  
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid justify={"center"} item xs={12} style={{ textAlign: "center" }} >
            <Typography variant="body1">Livestream konnte nicht geladen werden.</Typography>
          </Grid>
        </Grid>
      )}
  if(data && data.get3QToken){              

  }

    return (
      <>      
            <div id="myVideoPlayer"></div>
      </>
    )
  

}
