import { gql, useMutation, useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navigation/NavBar'
import Player from '../components/player3Q'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useAuthDispatch, useAuthState } from '../context/auth'
import VoteSong from '../components/poll/songs/VoteSong'
import VoteMood from '../components/poll/mood/VoteMood'
import Grid from "@material-ui/core/Grid"
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Guardien from '../components/Guardian'
import { Link } from "react-router-dom";
import Brainstorming from '../components/brainstorming/brainstorming'
import Grow from '@material-ui/core/Grow';
import ScrollToTop from '../components/scrollToTop'
import CircularProgress from '@material-ui/core/CircularProgress';



const GET_Elements = gql`
query getElements{
    getElements{
        elementId
      status
      elementJson
    }
}`


export default function Home(props) {

    const { error, loading, data, startPolling, stopPolling } = useQuery(GET_Elements,
        {
        
          
        })

        useEffect(() => {
          startPolling(10000)
        return () => {
         stopPolling()
        }
      }, [startPolling, stopPolling])

        if (data) {
          
            const mapElements = data.getElements.map((element) =>{
                let elementObj=JSON.parse(element.elementJson)

                return (
            
<>
{element.status=="active" &&
                    <Grow in={true}>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} style={{ textAlign: "center" }}>
                        {elementObj.type == "button" && <Link to={elementObj.link}><Button variant="contained" color="primary" size="large">{elementObj.label}</Button></Link>}
                        {elementObj.type == "brainstorming" && <Brainstorming />}               
                        {elementObj.type == "poll" && <VoteSong pollId={elementObj.pollId} />}         
                        {elementObj.type == "mood" && <VoteMood />}         

                    </Grid>
                  </Grow>}
              </>
                  )

            })
                
            
                  return(<>{mapElements}</>)
                
              
              } 
               if (loading) {
                return (
            
                  <Grid container direction="row" justify="center" spacing={2}>
                    <Grid justify={"center"} item xs={12} style={{ textAlign: "center" }} >
                      <CircularProgress color="secondary" />
                    </Grid>
                  </Grid>
                )
            
              }
            
              if (error) {
                return (
            
                  <Grid container direction="row" justify="center" spacing={2}>
                    <Grid justify={"center"} item xs={12} style={{ textAlign: "center" }} >
                      <Typography variant="body1">Modul konnte nicht geladen werden.</Typography>
                    </Grid>
                  </Grid>
                )
            
              }


}

