import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 footer: {
  position: "absolute", bottom: 0, width:"100%",
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
  justifyContent: "center"
 },
 flex: {
  margin: 0,
  display: "flex",
   flexFlow: "column wrap",
   justifyContent: "center",
   padding: "10px",
   
   [theme.breakpoints.up('sm')]: {
    flexDirection: "row"
  },
   
 },
 flexItem:{
  flexBasis: "auto",
  textAlign: "center",
  padding: "10px",
  borderLeft: "solid 0px white",
  [theme.breakpoints.up('sm')]: {
    borderLeft: "solid 0px white",
  },  
 },
 a: {
  color: "#666666",
  fontSize: "16px",
  fontWeight: 400
 },

}));


export default function Footer({id}) {
  const classes = useStyles()
  return (

<section className={classes.footer}>
<div className={classes.flex}>
        <div className={classes.flexItem}>
         <a className={classes.a} href="https://gls-group.eu/DE/de/impressum" target="_blank" rel="noreferrer"> <Typography variant="subtitle2">Impressum</Typography></a>
        </div>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://gls-group.eu/DE/de/datenschutz-standard" target="_blank" rel="noreferrer"><Typography variant="subtitle2">Datenschutz</Typography></a>
        </div>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://gls-group.eu/DE/de/agb-standard" target="_blank" rel="noreferrer"><Typography variant="subtitle2">AGB</Typography></a>
        </div>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://gls-group.eu/DE/de/haftungsausschluss" target="_blank" rel="noreferrer"><Typography variant="subtitle2">Haftungsausschluss</Typography></a>
        </div>
        <div className={classes.flexItem}>
          <a className={classes.a} href="https://wirkung.group/digital/" target="_blank" rel="noreferrer"><Typography variant="subtitle2">Technik</Typography></a>
        </div>
      </div>
</section>
     


  )
}
