import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Guardien from '../components/Guardian'
import NavBar from '../components/Navigation/NavBar'
import Player from '../components/player3Q'


const items = [{ link: "/logout", label: "Ausloggen" }]
const TITLE = 'GLS Event'




const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  main: {
    marginTop: "106px",
  
  },
  box: {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },

  video: {
    position: "relative",
    paddingBottom: "56.25%",
    paddingTop: "0px",
    height: "0",
    borderRadius: "10px",
    marginBottom: "-1px",
    marginTop: "50px",
  },
  btn: {
    borderRadius: 0,
  },
  videoFooter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: "70px",
backgroundColor: theme.palette.secondary.main,
    marginBottom: "30px",
  }

}));



export default function Home(props) {
  const classes = useStyles();

  useEffect(() => {

  }, []);


  return (
    <>
    <Guardien project={"basic"}/>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <NavBar items={items} burger={true} title={"Update Call GLS Germany"}/>

   

      <Container maxWidth="md" className={classes.main}>
        <div className={classes.box}>
          <div className={classes.video} id='vdiv'>  <Player project={"basic"}  /></div>         

        </div>
      </Container>

     
    </ >
  );
}

