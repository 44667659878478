import React, { Fragment, useEffect } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core';
import ApolloProvider from './ApolloProvider'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import './App.scss';

import { deDE } from '@material-ui/core/locale';
import { AuthProvider } from './context/auth'

import StreamBs from './pages/Stream'
import StreamSp from './pages/StreamSp'
import Landing from './pages/Landing'
import WelcomeDe from './pages/Welcome';
import Logout from './components/Navigation/Logout.js';
import AfterEvent from './pages/AfterEvent';
import Footer from './components/Footer'
import DynamicRoute from './util/DynamicRoute'
import TawkTo from './components/TawkTo';
import Quiz from './pages/Quiz'
import Selfie from './pages/Selfie'
import Postcard from './pages/Postcards'
import ThankYou from './pages/ThankYou'
import ScrollToTop from './components/scrollToTop';

const defaultTheme = createMuiTheme({

  palette: {
    primary: {
      main: '#fcbf00',
      contrastText: "#172154",
      light: '#fff',
      dark: "#F2B700",

    },
    secondary: {
      main: '#172154',
      contrastText: "#fff",
    },

  },
  typography: {
    fontFamily: [
      'Open Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      fontWeight: 600,
      fontStyle: "italic",
      fontSize: "15px"
    },


  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1600,
      xl: 2500,
    },
  },

}, deDE)

const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {

      h1: {
        color: '#172154',
      },
      h2: {
        color: '#172154',
        fontSize: "2rem",
        textAlign: "center",
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: "1.8rem",

        },

      },
      h3: {
        color: '#172154',
        fontSize: "1.5rem",
        fontWeight: 700,
      },
      h4: {
        color: '#172154',
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      h5: {
        color: '#172154',

      },
      h6: {
        color: '#172154',
      },
      body1: {
        color: '#172154',
        fontSize: "1.2rem"

      },
      body2: {
        color: '#172154',
        fontSize: "1rem"
      },
      subtitle2: {
        color: '#fff'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        minHeight: 50
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderRadius: 0,
        },
        "&$focused $notchedOutline": {
          borderRadius: 0,
        },

        "& .MuiSelect-root ~ $notchedOutline": {
          borderRadius: 0,
        },
        "&$focused .MuiSelect-root ~ $notchedOutline": {
          borderRadius: 0,
        },
        "& .MuiSelect-root": {
          borderRadius: 0,
        }
      }
    },



  }
}




const useStyles = makeStyles((theme) => ({

  container: {
    padding: '0', maxWidth: "100%", minHeight: "100vh", position: "relative", paddingBottom: "100px", display: "block",

    [theme.breakpoints.down('sm')]: {
      paddingBottom: "250px",
    },

  }


}));


function App() {
  const classes = useStyles()


  useEffect(() => {
  }, [])

  return (
    <Fragment>
      <ApolloProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <BrowserRouter >
              <ScrollToTop />
              <Container className={classes.container}>
                <Switch>
                  <DynamicRoute exact path="/" component={WelcomeDe} authenticated />
                  <DynamicRoute exact path="/landing" component={Landing} guest />
                  <DynamicRoute exact path="/update-call/stream" component={StreamBs} authenticated />
                  <DynamicRoute exact path="/kick-off/stream"  component={StreamSp} authenticated />
                  <DynamicRoute exact path="/kick-off/quiz"  ticket authenticated ><Quiz /> </DynamicRoute>
                  <DynamicRoute exact path="/kick-off/postcard" ticket authenticated ><Postcard /> </DynamicRoute>
                  <DynamicRoute exact path="/kick-off/foto-uploader" ticket authenticated guard ><Selfie /> </DynamicRoute>
                  <DynamicRoute exact path="/logout" authenticated ><Logout /></DynamicRoute>
                  <DynamicRoute exact path="/thankYou" authenticated ><ThankYou /></DynamicRoute>
                  <DynamicRoute exact path="/fin" authenticated ><AfterEvent /></DynamicRoute>
                  <DynamicRoute path="/:params" component={WelcomeDe} authenticated />
                  <DynamicRoute path="*" authenticated><Redirect to={"/"} /></DynamicRoute>
                </Switch>
                <TawkTo />
                <Footer />
              </Container>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>

  );
}

export default App;
