import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Guard from '../components/Guard'
import NavBar from '../components/Navigation/NavBar'
import { useAuthState } from '../context/auth'
import Selfie from "../components/selfie/TakeSelfie"
import PageLayout from '../components/layout/pageLayout'
import { gql } from '@apollo/client'


const items = [{ link: "/kick-off/stream", label: "Livestream" },{ link: "/logout", label: "Ausloggen" }]

const TITLE = 'GLS Event'





const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },



}));



export default function Home(props) {
  const classes = useStyles();
  const {user} = useAuthState()



  useEffect(() => {

  }, []);



  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <NavBar items={items} burger={true} title={"Kick-Off GLS Germany 2021"}/>

   
    <PageLayout>
    <Selfie/>
    </PageLayout>


   


    </ >
  );
}

