import {
  ApolloClient,

  ApolloProvider as Provider,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import { RetryLink } from "@apollo/client/link/retry";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors){
    graphQLErrors.forEach((err) => {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
      window.location.href = '/logout'        
      }      
    console.log(
      `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
    )},
  )
  }
   

  if (networkError) console.log(`[Network error]: ${networkError}`);
});


let httpLink = createUploadLink({
  uri: 'https://admin.wirkung.stream/graphql/',
})

const retry = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

//httpLink =  authLink.concat(httpLink)



const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink.concat(retry))),
        cache: new InMemoryCache(),
        defaultOptions : {
          watchQuery : {
            fetchPolicy : 'network-only',
          }
        },
        
})

export default function ApolloProvider(props) {
  return <Provider client={client} {...props} />
}