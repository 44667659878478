import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';



export default function Selfie({children}) {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Container maxWidth="md" style={{ marginTop: 80 }}>
          <Link to="/kick-off/stream">
                            <Button variant={"contained"} color="primary" size={"large"} style={{marginBottom: "20px"}} fullWidth={smDown}
                            >Zurück zum Livestream</Button>
                        </Link>
            <Paper elevation={3} style={{ padding: 40, margin: "auto" }}>
                <Grid container justify="center">
                    {children}
                </Grid>
            </Paper>
        </Container>
    )
}