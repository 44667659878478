import React from 'react'
import { useAuthState } from '../context/auth'
import { Route, Redirect, useHistory } from 'react-router-dom'
import Guard from '../components/Guard'
import { gql, useQuery } from '@apollo/client'


const GET_SELFIE = gql`
  query getSelfie{
    getSelfie
  }
`;

const GET_NEXT_QQUESTION = gql`
query getNextQuestion{
    getNextQuestion{
    id,
    text,
    title,
    countdown,
    images{image,title},
    answers
    {id, label, color, isCorrect}
  }
}
`



export default function DynamicRoute(props) {
    const { user } = useAuthState()


    if (props.authenticated && !user) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/landing' }} />
    }
    if (props.authenticated && props.ticket) {
        if(user.ticket !="special" && user.ticket!="sp-admin"){
        return <Redirect to={{ pathname: '/' }} />
        }
    }
    if (props.authenticated && props.guard) {
        return <>
            <Guard gql={GET_SELFIE} linkError="/thankYou"> <Route component={props.component}{...props} /> </Guard>
        </>
    }


    // check if route is restricted by role
    if (props.authenticated && props.admin && user.role != 'admin') {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
    }
    if (props.guest && user) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/' }} />
    }

    // authorised so return component

    return <Route component={props.component}{...props} />





}
