import { gql, useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navigation/NavBar'
import Player from '../components/player3Q'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useAuthDispatch, useAuthState } from '../context/auth'
import VoteSong from '../components/poll/songs/VoteSong'
import VoteMood from '../components/poll/mood/VoteMood'
import Grid from "@material-ui/core/Grid"
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Guardien from '../components/Guardian'
import { Link } from "react-router-dom";
import Brainstorming from '../components/brainstorming/brainstorming'
import Grow from '@material-ui/core/Grow';
import ScrollToTop from '../components/scrollToTop'
import CircularProgress from '@material-ui/core/CircularProgress';
import PollElements from '../components/pollElements'


const items = [{ link: "/logout", label: "Ausloggen" }]

const TITLE = 'gls-event'


const ADD_QUESTION = gql`
  mutation addQandA($roomId: Int!, $author: String!, $content: String!) {
    addQandA(roomId:$roomId, author: $author ,content: $content)
  }
`;

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  main: {
    marginTop: "106px",

  },

  box: {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },

  video: {
    position: "relative",
    paddingBottom: "56.25%",
    paddingTop: "0px",
    height: "0",
    borderRadius: "10px",
    marginBottom: "-1px",
    marginTop: "50px",
  },
  btn: {
    borderRadius: 0,
  },
  videoFooter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: "70px",
    backgroundColor: theme.palette.secondary.main,
    marginBottom: "30px",
  }

}));



export default function Home(props) {
  const classes = useStyles();
  const { user } = useAuthState()

  const [open, setOpen] = React.useState(false);

  const handleClickQuestion = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [variables, setVariables] = useState({
    anonym: false,
    content: '',
    author: user.alias

  })

  const [addQandA, { data, loading }] = useMutation(ADD_QUESTION);


  const handleSubmitQuestion = () => {

    if (variables.anonym) {
      addQandA({ variables: { roomId: 2, author: 'Anonym', content: variables.content } });

    } else {
      addQandA({ variables: { roomId: 2, author: user.alias, content: variables.content } });

    }
    setVariables({ ...variables, content: "" })
    setOpen(false);


  };

  useEffect(() => {

  }, []);


  return (
    <>
      <Guardien project={"special"} />
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <NavBar items={items} burger={true} title={"Kick-Off GLS Germany 2021"} />



      <Container maxWidth="md" className={classes.main}>
        <div className={classes.box}>
          <div className={classes.video} id='vdiv'>  <Player project={"special"} /></div>
          <div className={classes.videoFooter}>
            <Button
              variant="contained"
              className={classes.btn}
              size="large"
              color="primary"
              onClick={handleClickQuestion}
            >Frage ins Studio</Button>
          </div>

        </div>

        <Grid container direction="row" justify="center" spacing={4}>

          
         

          <PollElements/>

        </Grid>
      </Container>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Frage ins Studio</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmitQuestion()

          }}
        >
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1">  Sie können hier Ihre Frage einreichen. </Typography>
            </DialogContentText>
            <TextField
              margin="dense"
              id="alias"
              defaultValue={variables.author}
              type="text"
              fullWidth
              variant="outlined"
              color="secondary"
              placeholder="Ihr Name"
              inputProps={{ maxLength: 20 }}
              required
              disabled={variables.anonym}

              onChange={e => setVariables({ ...variables, author: e.target.value })}

            />
            <TextField
              margin="dense"
              id="question"
              label="Ihre Frage..."
              type="text"
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              color="secondary"
              value={variables.content}
              onChange={e => setVariables({ ...variables, content: e.target.value })}
              inputProps={{ maxLength: 500 }}
              required
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={variables.anonym}
                  inputProps={{ 'aria-label': 'anonym' }}
                  onChange={e => setVariables({ ...variables, anonym: e.target.checked })}

                />}
              label="Anonym" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Abbrechen
          </Button>
            <Button type="submit" color="secondary">
              {(loading) ? <CircularProgress size={20} color="secondary" /> : "Einreichen"}

            </Button>
          </DialogActions>
        </form>
      </Dialog>


    </ >
  );
}

