import { gql, useMutation, useQuery } from '@apollo/client'
import Button from "@material-ui/core/Button"
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { Redirect } from 'react-router'
import Dialog from '../../Dialog'
import MaterialDialog from '@material-ui/core/Dialog';
import { light } from '@material-ui/core/styles/createPalette'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const completedLink = "/thankYou"
const spinner = <Grid container direction="column"
    alignItems="center"
    justify="center" spacing={2} style={{ height: "100%" }}>
    <Grid container item xs={12} justify="center" alignItems="center"
        justify="center" style={{ textAlign: "center" }} >
        <CircularProgress color="secondary" />
    </Grid>
</Grid>

const useStyles = makeStyles((theme) => ({
    image: {
        width: "100%",
        '&:hover':{
            cursor: "pointer",
        }
    },
    buttons: {
        marginTop: "25px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
            marginBottom: "25px",
        },
    },
    paper: {
        aspectRatio: 1.41,
    },
    spinner: {
        margin: "0 auto",
        marginTop: "50%"
    },
    



}))

const GET_POSTCARD = gql`
query getPostcards{
  getPostcards{
   id, url
  }
}
`


const CHOOSE_POSTCARD = gql`
mutation choosePostcard($postcardId: Int!){
    choosePostcard(postcardId:$postcardId)
}`


export default function Postcard() {
    const classes = useStyles();
    let content = spinner
    const [render, rerender] = React.useState(null);
    const [lightbox, setLightbox] = React.useState([false, false]);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const { loading, error, data, refetch } = useQuery(GET_POSTCARD, {
        fetchPolicy: "network-only"
    });
    const [choosePostcard, { loading: mutationLoading, error: mutationError }] = useMutation(CHOOSE_POSTCARD, {
        onCompleted: (data) => {
            rerender(true)
            refetch()
        },

        onError: (error) => { setDialogOpen(true) }
    })





    if (data && data.getPostcards) {
        content = data.getPostcards.map((element, index) => {

            return (
                <Grid item justify="center" xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }} >
                    <Paper elevation={3} className={classes.paper}>
                        {(!loading || !mutationLoading || !error) && <img src={element.url} alt="" className={classes.image} onClick={() => setLightbox(()=>{let arr=[...lightbox]; arr[index]=true; setLightbox(arr)})} />}
                    </Paper>
                    <Button variant="contained" size="large" color="primary" style={{ minWidth: 160 }} className={classes.buttons} disabled={(!data || loading || mutationLoading || error || mutationError) ? true : false}
                        onClick={() => {
                            choosePostcard({ variables: { postcardId: element.id } })
                        }}
                    >Bild {index + 1} auswählen
                    </Button>
                    <MaterialDialog fullWidth="true"
        maxWidth={"md"}
                     onClose={() => setLightbox(()=>{let arr=[...lightbox]; arr[index]=false; setLightbox(arr)})} aria-labelledby="customized-dialog-title" open={lightbox? lightbox[index] : false}>
                        <DialogTitle id="customized-dialog-title" onClose={() => setLightbox(()=>{let arr=[...lightbox]; arr[index]=false; setLightbox(arr)})}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setLightbox(()=>{let arr=[...lightbox]; arr[index]=false; setLightbox(arr)})}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
                        <DialogContent dividers>
                        <img src={element.url} alt="" className={classes.image}  />
                        </DialogContent>

                    </MaterialDialog>
                </Grid>
            )
        })
    }





    if (error) {
        if (error.message == "Forbidden") {
            return (<Redirect to={"/thankYou"} />)

        }
    }



    return <Grid container direction="column" justify="center" spacing={2} className={classes.mainGrid}>
        <Grid container justify="center" style={{ marginBottom: 50 }}>
            <Typography variant="h2">
                BITTE WÄHLEN SIE: <br></br>Was heißt für Sie "Kunde im Zentrum" <br></br>(Bild 1 oder Bild 2)?
          </Typography>

        </Grid>
        <Grid container direction="row" spacing={3} justify="center" >
            {content}
        </Grid>
        <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
        </Grid>
        {dialogOpen && <Dialog isOpen={dialogOpen} cancelLabel={"Ok"} cancelOnClick={() => setDialogOpen(false)} title={"Fehler"} content={"Es ist ein Fehler aufgetreten."} />}




    </Grid>










}






