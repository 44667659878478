import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client'
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Grow';


const GET_POLL = gql`
query getPoll($pollId:Int!){
    getPoll(pollId:$pollId){
        pollId,
        title,
        description, 
        canChangeVote, 
        totalVotes, 
        showTotal, 
        isAbsolute, 
        myAnswer,
        answers
        {id,
        image,
        size,
        label, 
        votes}
    }
}`

const ANSWER_POLL = gql`
mutation answerPoll($pollId:Int!, $answer:Int!){
  answerPoll(pollId:$pollId,answer:$answer)
}
, `

const useStyles = makeStyles({
  noClick: {
  }
  , noDisplay: {
    display: "none"
  },
  fill: {
    backgroundColor: "#172154",
    opacity: 0.2,
    height: "101%",
    position: "absolute",
    left: -1,
  },
  percentage: {
    position: "absolute",
    right: 20,
  },
  hidePercentage: {
    display: "none"
  },
  highlightPercentage: {
    backgroundColor: "#172154"
  },
  gridContainer: {
    border: "2px solid #172154",
  },
  highlightContainer: {
    border: "2px solid #172154",
  },
  highlightContent: {
    backgroundColor: "#172154",
    opacity: 0.5,
    height: "101%",
    position: "absolute",
    left: 0,
  },
  buttonContainer: {
    width: "100%",
    padding: 0,
    height: 60,
  },
  placeAvatar:{
    width: "60px",
    height: "100%",
    padding: "3px 3px 3px 3px",
    position:"absolute",
    left: -1,
    marginLeft: "10px",

zIndex: 100
  },
  avatar: {
    height: "100%",
    width: "auto",
  },
  btn: {
    disabled: "#172154",
    borderRadius: 0,

  }
})

export default function VoteMood() {
  const classes = useStyles();
  const { error, loading, data, startPolling, stopPolling } = useQuery(GET_POLL,
    {
      variables: {
        pollId: 2
      },
    })
  const [answerPoll] = useMutation(ANSWER_POLL);
  const [answer, setAnswer] = useState({
    pollId: 2,
    answer: 0
  })

  const [click, setClick] = useState({
    bool: true,
    highlight: 0
  })

  
  useEffect(() => {
    startPolling(10000)
  return () => {
   stopPolling()
  }
}, [startPolling, stopPolling])

  const calcPercentage = (val, totalVal) => {
    return (val / totalVal * 100).toFixed(1)
  }


  if (data) {

if(data.getPoll.status =="inactive") return null


    const getData = data.getPoll;
    const getAnswers = data.getPoll.answers;
    const mapAnswers = getAnswers.map((getAnswer) => {
      if (getData.myAnswer > 0) {
        click.bool = getData.canChangeVote
        click.highlight = getData.myAnswer
      }
      let displayVote = calcPercentage(getAnswer.votes, getData.totalVotes);
      if (getData.isAbsolute) {
        displayVote = getAnswer.votes;
      }
      return (

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justify="center"
            className={(click.highlight === getAnswer.id) ? classes.highlightContainer : classes.gridContainer}
            style={{ padding: 0 }}>
            <Button 
            onClick={(e => {
              e.preventDefault()
              setClick({
                ...click,
                highlight: getAnswer.id
              })
              answerPoll({
                variables: {
                  pollId: 2,
                  answer: getAnswer.id
                },
                refetchQueries: [{
                  query: GET_POLL,
                  variables: { pollId: 2 },
                }]
              })

            })} className={classes.buttonContainer} style={{ justifyContent: "flex-start", paddingLeft: "90px", color: "#172154" }}>{getAnswer.label}
              <div className={classes.placeAvatar}><Avatar className={classes.avatar} alt="Remy Sharp" src={getAnswer.image} /></div>
              <div
                className={(click.highlight === getAnswer.id) ? classes.highlightContent : classes.fill}
                style={{ width: calcPercentage(getAnswer.votes, getData.totalVotes) + "%" }}>
              </div>
              <p className={classes.percentage}>{displayVote}%</p>
            </Button>
          </Grid>
        </Grid>

      )
    })
    return (

      <Grid container direction="column" justify="center" spacing={2}>
        <Grid container justify={"left"} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant={"h3"} component={"h1"}>{getData.title}</Typography>
        </Grid>
        <Grid container justify={"left"} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant={"h6"} component={"h1"}>{getData.description}</Typography>
        </Grid>

        {mapAnswers}

        <Grid container justify={"center"} item xs={12} sm={12} md={12} lg={12} xl={12}>
          {getData.showTotal && <Typography variant={"h7"}  >Eingereichte Stimmen: {getData.totalVotes}</Typography>}
        </Grid>
      </Grid>

    )
  } 
   if (loading) {
    return (

      <Grid container direction="row" justify="center" spacing={2}>
        <Grid justify={"center"} item xs={12} style={{ textAlign: "center" }} >
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
    )

  }

  if (error) {
    return (

      <Grid container direction="row" justify="center" spacing={2}>
        <Grid justify={"center"} item xs={12} style={{ textAlign: "center" }} >
          <Typography variant="body1">Modul konnte nicht geladen werden.</Typography>
        </Grid>
      </Grid>
    )

  }
}






