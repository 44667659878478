import { gql, useMutation } from '@apollo/client';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import React, { useState } from 'react';
import { useAuthState } from '../../context/auth';



const ADD_QUESTION = gql`
  mutation addQandA($roomId: Int!, $author: String!, $content: String!) {
    addQandA(roomId:$roomId, author: $author ,content: $content)
  }
`;

const useStyles = makeStyles({
  root: {
    marginTop: "16px"
  },

})

export default function Brainstorming() {
  const classes = useStyles();


  const { user } = useAuthState()

  const [variables, setVariables] = useState({
    anonym: false,
    content: '',
    author: user.alias
  })

  const [addQandA, { data, loading, error }] = useMutation(ADD_QUESTION);


  const handleSubmitQuestion = () => {

    if (variables.anonym) {
      addQandA({ variables: { roomId: 1, author: 'Anonym', content: variables.content } });

    } else {
      addQandA({ variables: { roomId: 1, author: user.alias, content: variables.content } });

    }
    setVariables({ ...variables, content: "" })

  };


  if(loading){

  }
  if(error){
    
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmitQuestion()

      }}
    >
      <Grid container direction="column" justify="center" spacing={2} className={classes.root}>
        <Grid container justify={"left"} item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: "left"}}>
          <Typography variant={"h3"} component={"h1"}>Jetzt sind Sie gefragt:</Typography>
        </Grid>
        <Grid container justify={"left"} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant={"h6"} component={"h1"} style={{textAlign: "left"}}>Stellen Sie sich einen Ihrer Kunden vor und überlegen Sie, was Sie persönlich ab morgen für diesen Kunden tun können.</Typography>
        </Grid>
        <Grid container justify={"left"} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            margin="dense"
            id="alias"
            defaultValue={variables.author}
            type="text"
            fullWidth
            variant="outlined"
            color="secondary"
            placeholder="Ihr Name"
            inputProps={{ maxLength: 20 }}
            required
            disabled={variables.anonym}

            onChange={e => setVariables({ ...variables, author: e.target.value })}

          />
          <TextField
            margin="dense"
            id="question"
            label="Ihre Antwort..."
            type="text"
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            color="secondary"
            onChange={e => setVariables({ ...variables, content: e.target.value })}
            inputProps={{ maxLength: 500 }}
            required
            value={variables.content}

          />
        </Grid>
        <Grid container direction="row" justify="space-between" style={{ padding: "0px 8px" }} >
          <FormControlLabel
            control={
              <Checkbox
                checked={variables.anonym}
                inputProps={{ 'aria-label': 'anonym' }}
                onChange={e => setVariables({ ...variables, anonym: e.target.checked })}

              />}
            label="Anonym" />
          <Button color="primary" type="submit" variant="contained" color="primary" disabled={loading} style={{minWidth: 200}}>
          {(loading) ? <CircularProgress size={20} color="secondary" /> : "Antwort einsenden"} 
          </Button>
        </Grid>
      </Grid>
    </form>

  )

}






