import { useEffect } from 'react';
import tawkTo from "tawkto-react";




const tawkToPropertyId = '6076d921f7ce1827093a51f2'
const tawkToKey = '1f3840pq1'

function TawkTo() {

  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey)
  }, [])

  return null
  
}

export default TawkTo;
