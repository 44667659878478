import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthDispatch, useAuthState } from '../context/auth';
import NavBar from '../components/Navigation/NavBar';
import Typography from '@material-ui/core/Typography';

const items = [{ link: "/logout", label: "Ausloggen" }]

const titleBasic = "Update Call GLS Germany"
const titleSpecial = "Kick-Off GLS Germany 2021"
let content = null
let dialogContent = null
let title = ""
const TITLE = 'GLS Event'




const dialogSpecial = <>         <Typography variant="body2">Ab 09:30 Uhr gelangen Sie zum Livestream.</Typography>
  <Typography variant="body2">Ab 10:00 Uhr beginnt das Event.</Typography></>

const dialogBasic= <>         <Typography variant="body2">Ab 10:30 Uhr gelangen Sie zum Livestream.</Typography>
  <Typography variant="body2">Ab 11:00 Uhr beginnt das Event.</Typography></>


const useStyles = makeStyles((theme) => ({

  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    color: "#0B5AA1 !important",
    marginTop: "20%",
    marginBottom: "2%",
    maxWidth: "750px",
    color: "#0B5AA1",
    margin: "50px 120px",
    margin: "auto",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",


    '&:hover': {
      color: "#073F71 !important"
    },

  },

  a: {
    color: "#0B5AA1 !important",

    '&:hover': {
      color: "#073F71 !important",

    }
  },
  btn: {
    borderRadius: 0,
  }

}));





export default function Home(props) {

  const classes = useStyles()
  let { params } = useParams()
  const [open, setOpen] = React.useState(params)

  const { user } = useAuthState()


  const handleClose = () => {
    setOpen(false);
  };

  const contentBasic = (
    <div className={classes.box}>
      <div className="card-content" style={{ padding: '28px' }}>
        <Typography paragraph variant="h3">Herzlich Willkommen</Typography>
        <Typography variant="h4">zum Update Call der GLS Germany am 22. April 2021.</Typography>
        <br></br>
        <Typography variant="body1" paragraph >Es erwarten Sie weitere Details zu wichtigen strategischen Themen aus ACCELERATE GLS.

Ich freue mich auf Ihre Teilnahme und eine erfolgreiche Tagung. <br></br><br></br>

Herzliche Grüße,<br></br>
Dr. Karl Pfaff </Typography>

      </div>
      <div className="video-footer card-footer">
        <Link to={"/update-call/stream"}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.btn}
          >Zum Livestream</Button>
        </Link>

      </div>
    </div>
  )

  const contentSpezial = (
    <div className={classes.box}>
      <div className="card-content" style={{ padding: '28px' }}>
        <Typography paragraph variant="h3">Herzlich Willkommen</Typography>
        <Typography variant="h4">zum Kick-Off der GLS Germany am 22. April 2021.</Typography>
        <br></br>
        <Typography variant="body1" paragraph >Es erwarten Sie weitere Details zu wichtigen strategischen Themen aus ACCELERATE GLS. Darüber hinaus dürfen Sie sich auf ein abwechslungsreiches Programm und unterhaltsame Überraschungen freuen.<br></br><br></br>
Herzliche Grüße,<br></br>
Dr. Karl Pfaff </Typography>

      </div>
      <div className="video-footer card-footer">
        <Link to={"/kick-off/stream"}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.btn}
          >Zum Livestream</Button>
        </Link>

      </div>
    </div>
  )




  if (user.ticket === "basic") {
    content = contentBasic
    title = titleBasic
    dialogContent = dialogBasic
  }
  if (user.ticket === "special") {
    content = contentSpezial
    title = titleSpecial
    dialogContent = dialogSpecial

  }
  if (user.ticket === "bs-admin") {
    content = contentBasic
    title = titleBasic
    dialogContent = dialogBasic

  }
  if (user.ticket === "sp-admin") {
    content = contentSpezial
    title = titleSpecial
    dialogContent = dialogSpecial

  }


  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <NavBar items={items} burger={true} title={title} />



      <Container maxWidth="md">

        {content}
      </Container>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Am Donnerstag, den 22.04.2021 ist es soweit.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContent}


          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Link to={"/logout"}>
            <Button color="secondary">
              Ausloggen
          </Button>
          </Link>
          <Button onClick={handleClose} color="secondary">
            Schließen
          </Button>
        </DialogActions >

      </Dialog>

    </>
  )
}

