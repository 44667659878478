import { gql, useLazyQuery, useMutation } from '@apollo/client'
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import md5 from 'md5'
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import Webcam from "react-webcam"
import Dialog from '../../Dialog'
import { Redirect } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { TextareaAutosize } from '@material-ui/core'
import Fab from '@material-ui/core/Fab';


const completedLink = "/thankYou"
const spinner = <Grid container direction="column"
    alignItems="center"
    justify="center" spacing={2} style={{ height: "100%" }}>
    <Grid container item xs={12} justify="center" alignItems="center"
        justify="center" style={{ textAlign: "center" }} >
        <CircularProgress color="secondary" />
    </Grid>
</Grid>

const useStyles = makeStyles((theme) => ({
    image: {
        width: "100%"
    },
    buttons: {
        marginTop: "20px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
            marginBottom: "25px",
        },
    },
    paper: {
        aspectRatio: 1.41,
    },
    spinner: {
        margin: "0 auto",
        marginTop: "50%"
    }



}))

const GET_NEXT_QQUESTION = gql`
query getNextQuestion{
    getNextQuestion{
    id,
    text,
    title,
    countdown,
    images{image,title},
    answers
    {id, label, color, isCorrect}
  }
}
`
const ANSWER_QQUESTION = gql`
mutation answerQuestion($questionId: Int!, $isCorrect: Boolean!, $time: Int!){
  answerQuestion(questionId:$questionId,isCorrect:$isCorrect,time:$time)
}`



export default function Quiz() {

    const classes = useStyles();
    let content = spinner
    const [render, rerender] = React.useState(null);
    const [running, setRunning] = useState(false)

    const [clickState, setClickState] = useState(
        {
            questionId: 0,
            answerId: 0,
            isCorrect: false,
            time: 0
        }
    )
    const [getQuestions, { loading, error, data }] = useLazyQuery(GET_NEXT_QQUESTION, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setCountdown(15);
            setClickState({questionId: data.getNextQuestion.id,
                isCorrect: false,
                time: 15})
        },

    });
    const [answerQuestion, { loading: mutationLoading, error: mutationError }] = useMutation(ANSWER_QQUESTION, {
        onCompleted: (data) => {
            rerender(true)
        },

        onError: (error) => { }
    })

    const [state, setState] = useState(true)

 

    const [countdown, setCountdown] = useState(15);

    useEffect(() => {
        const timer =
            countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
        if (countdown === 0 && running) {
            setState(false)
        }
        return () => clearInterval(timer);
    }, [countdown]);
    let getData
    let getImages
    let getAnswers


    if (error) {
        if (error.message == "Forbidden") {
            return (<Redirect to={"/thankYou"} />)

        }
    }

    if (data) {


        getData = data.getNextQuestion;
        getImages = data.getNextQuestion.images;
        getAnswers = data.getNextQuestion.answers;

        const mapImages = getImages.map((getImage) => {

            return (
                <Grid container item justify="center" xs={12} sm={6} md={6} lg={6} xl={6}>
                    <img src={getImage.image} width="100%" alt={getImage.title} />
                </Grid>
            )
        })

        const mapButtons = getAnswers.map((getAnswer) => {
            return (
                <Grid container item justify={"center"} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button variant="contained" size="large" color="inherit" style={state ? { minWidth: "160px", backgroundColor: getAnswer.color, color: "white", borderRadius: 0 } : { minWidth: "160px", backgroundColor: getAnswer.color, color: "white", borderRadius: 0, opacity: 0.6 }}
                        onClick={(e => {
                            e.preventDefault()
                            setState(
                                false)
                            
                            answerQuestion({
                                variables: {
                                    questionId: getData.id,
                                    isCorrect: getAnswer.isCorrect,
                                    time: countdown
                                }
                            })                            
                        })
                        }
                        disabled={!state} >{getAnswer.label}</Button>
                </Grid>
            )
        })

        let returnNext = (
            <Grid container justify="flex-end" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 25 }}>
                <Fab color="primary" size="large" disabled={state}
                    style={{ height: 120, width: 120 }}
                    onClick={(e => {
                        e.preventDefault()
                        setState(true)
                        setCountdown(15)
                        getQuestions()
                    })
                    }><h5
                        style={(state.bool === true) ? { color: "black" } : { color: "white" }}>{(countdown > 0 && state.bool === true) ? countdown : "Weiter"}</h5>
                </Fab>
            </Grid>
        )

      



        return (
            <div>
                <Grid container direction="column" justify="center" spacing={2} className={classes.mainGrid}>
                    <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
                        <Typography variant="h2">
                            BITTE WÄHLEN SIE: <br></br>Sind die folgenden Aussagen wahr oder falsch?
              </Typography>
                        <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 25 }}>
                            <Typography variant="h3" >
                                Die Zeit läuft:
                  </Typography>

                        </Grid>
                        <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }}>
                            <Typography variant="h4"  >
                                {countdown} Sek.
                  </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
                        <Typography variant="h6" style={{textAlign: "center"}}>
                            {getData.text}
                        </Typography>
                    </Grid>
                    <Grid container item direction="row" spacing={1} justify="center" xs={12} sm={12} md={12} lg={12} xl={12}>
                        {mapImages}
                    </Grid>
                    <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
                    </Grid>
                    <Grid container item justify="center" display="row" spacing={1} xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }}>
                        {mapButtons}
                    </Grid>
                    {returnNext}

                </Grid>
            </div>
        )


    }



    if (!running) {
        return (
            <div>
                <Grid container justify="center">
                    <Grid container item justify={"center"} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant={"h5"} style={{ textAlign: "center" }}>
                            Wahr oder falsch – Das GLS Quiz
                    </Typography>
                    </Grid>
                    <Grid container item spacing={3} justify={"center"} xs={12} sm={12} md={12} lg={12} xl={12}
                        style={{ marginTop: 30 }}>

                        <Grid item container justify={"center"} xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Button variant={"contained"} color="primary" size={"large"}                        
                                onClick={() => { getQuestions(); setRunning(true);  }}
                            >Zum Quiz
                    </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return <div>
        <Grid container direction="column" justify="center" spacing={2} className={classes.mainGrid}>
            <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
                <Typography variant="h2">
                    BITTE WÄHLEN SIE: <br></br>Sind die folgenden Aussagen wahr oder falsch?
</Typography>
                <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 25 }}>
                    <Typography variant="h3" >
                        Die Zeit läuft:
  </Typography>

                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }}>

                    <Typography variant="h4"  >
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
                <Typography variant="h6">
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={1} justify="center" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container item justify="center" xs={12} sm={6} md={6} lg={6} xl={6}>
                </Grid>
            </Grid>
            <Grid container justify="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
            </Grid>
            <Grid container item justify="center" display="row" spacing={1} xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }}>
            <Grid container item justify={"center"} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button variant="contained" size="large" color="inherit"  style={ {minWidth: "160px",  color: "white", borderRadius: 0, opacity: 0.6 }}
                        
                        disabled={true} >Wahr</Button>
                        
                </Grid>
                <Grid container item justify={"center"} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button variant="contained" size="large" color="inherit"  style={ {minWidth: "160px",  color: "white", borderRadius: 0, opacity: 0.6 }}
                        
                        disabled={true} >Wahr</Button>
                        
                </Grid>
            </Grid>

        </Grid>
    </div>



}






