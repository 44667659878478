
import { gql, useLazyQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useAuthDispatch } from '../context/auth';

const Flex = styled.div`
margin: 0;
 display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.countdownNumber}; 
`
const FlexItem = styled.div`
margin: auto;
`
const Card = styled.div`
border-radius: 0px;
border: none;
    min-width: 300px;
    max-width: 600px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #E7E8EE;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);   
`
const CardHeader = styled.div`
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const Form = styled.form`
`
const Input = styled.input`
    padding: 15px;
    width: 100%;
    border: none;
`
const Button = styled.button`
display: block; 
width: 100%;
border: none;
`
const LOGIN_USER = gql`
  query customerLogin($email: String!, $password: String!){
    customerLogin(email: $email, password: $password){
      customerId,token, alias
    }
  }
`;

export default function Login(props) {

  const [variables, setVariables] = useState({
    email: '',
    password: '',
  })

  const [errors, setErorrs] = useState({})


  const dispatch = useAuthDispatch()

  const [loginUser, { loading, data }] = useLazyQuery(LOGIN_USER, {
    onError(err) {
      if(err.graphQLErrors[0]){
        setErorrs(err.graphQLErrors[0].extensions.errors)
      }
      else{
        setErorrs("API ERROR")
      }
    },
    onCompleted(data) {
      dispatch({ type: 'LOGIN', payload: data.customerLogin })
      window.location.href = '/'
    }
  });

  const submitLoginForm = e => {
    e.preventDefault()
    loginUser({ variables })
    

  }


  return (
    <Fragment>
      <Flex className="loginForm py-5 justify-content-center">
        <FlexItem sm={10} md={8} lg={6} xl={4} className="my-auto">
          <Form onSubmit={submitLoginForm} style={{textAlign: "center"}}>            
            <Card className="login-card-header" style={{borderRadius:"5px"}}>              
              <div>   
              <Input
                type="email"
                value={variables.email}
                onChange={e => setVariables({ ...variables, email: e.target.value })}
                placeholder={errors.email ?? 'Benutzername'}
              />           
              <Input
                type="password"
                value={variables.password}
                onChange={e => setVariables({ ...variables, password: e.target.value })}s
                placeholder={"Passwort"}
              />
              </div>                       
              <Button className="login-btn" type="submit" disabled={loading}>{(loading || data) ? <CircularProgress size={20} style={{color:"white"}} /> : "Einloggen"}</Button>
            </Card>
            <br/>
            <span className="pt-3" style={{color: '#E3000B'}}>&nbsp;{(errors=="API ERROR") ? 'Service vorübergehend nicht erreichbar.. ': ((errors.email || errors.password || errors.data=='Ungültige Zugangsdaten') && 'Ungültige Zugangsdaten' )}&nbsp;</span>

          </Form>
        </FlexItem>
      </Flex>
    </Fragment>
  )
}
