import { gql, useMutation } from '@apollo/client'
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import md5 from 'md5'
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import Webcam from "react-webcam"
import Dialog from '../Dialog'
import {    
    isMobile
  } from "react-device-detect";

const errorDialog = {
    title: "Fehler bei der Übemittlung", 
    content: <><Typography variant="body1" paragraph> Es ist ein Fehler bei der Übermittelung des Fotos aufgetreten. Bitte prüfen Sie Ihre Verbindung und versuchen Sie es erneut.</Typography></>,
    cancelLabel: "Ok",
}

const consentDialog = {
    title: "Mein Foto übermitteln", 
    content: <><Typography variant="body1" paragraph> Hiermit gebe ich im Rahmen der Erstellung eines virtuellen Gruppenbildes mit der Übermittlung mein Einverständnis zur Verwendung meines Fotos nach den folgenden Bedingungen:</Typography> <Typography variant="body1"> <a target="_blank" rel="noopener noreferrer" href="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/gls/content/Kick-Off_Einwilligungserklaerung-Fotos-Videos_Mitarbeiter_2021-04_FINAL.pdf">Einwilligungserklärung zur Nutzung meiner Fotoaufnahme</a></Typography></>,
    okLabel: "Mein Foto übermitteln",
    cancelLabel: "Abbrechen",
}

const completedLink = "/thankYou"


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "300px",

        [theme.breakpoints.up('sm')]: {
            width: "360px",
        },
        [theme.breakpoints.up('md')]: {
            width: "400px",
        },
        [theme.breakpoints.up('xl')]: {
            width: "640px",
        },

        

    },
    error: {
        padding: 15
    },
    mediaError: {
        height: 0
    },
    image: {
        width: "100%",

    },



}))

const UPLOAD_SELFIE = gql`
 mutation uploadSelfie($image: String!, $checksum: String!) {
    uploadSelfie(image: $image, checksum: $checksum) 
  }
`


export default function TakeSelfie() {
    let history = useHistory();
    const classes = useStyles()
    const [mediaError, setMediaError] = useState(false)
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [captured, setCaptured] = useState(false)
    const [dialogOpen, setDialogOpen] = React.useState({errorDialog: false, consentDialog: false});
    const [consent, setConsent] = React.useState(false);
    const [uploadImage, { loading, error }] = useMutation(UPLOAD_SELFIE, {
        onCompleted: (data) => {
            setDialogOpen({...dialogOpen, consentDialog: false})
            history.push(completedLink)
        },
        
        onError: (error) => { setDialogOpen({consentDialog: false, errorDialog: true}); setConsent(false)  }
      })


    const videoConstraints = {
        width: 480,
        height: 640,
        aspectRatio: 3/4,
        facingMode: "user"
    };


    const handleUserMediaError = () => {
        setMediaError(true)
    }

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot({ width: 1440, height: 1920 });
        setImgSrc(imageSrc);
        setCaptured(true)
    }, [webcamRef, setImgSrc]);

    const toggleCapture = () => {
        if (!captured) {
            capture()
        }
        else {
            setCaptured(false)
            setImgSrc(null);
        }
    }

    useEffect(() => {
        if (captured && consent) {
            let checksum = md5(imgSrc)
            uploadImage({ variables: { image: imgSrc, checksum: checksum } })
        }

    }, [consent]);

    if (isMobile) {
        return     <Typography variant="h4" >Diese Funktion steht auf dem Smartphone leider nicht zur verfügen.</Typography>

    }


    return <>
        <Grid container >
            <Grid item container direction={"row"} justify={"center"} xs={12} >
                <Grid container justify={"center"} item xs={12} >
                    <Typography variant={"h2"} align={"center"}>Teilen Sie Ihr schönstes Lächeln.<br /> Übermitteln Sie uns Ihr Selfie.</Typography>
                </Grid>
                <Paper elevation={5} className={classes.paper}>
                        {mediaError && <Typography variant={"body1"} align={"center"} className={classes.error}><b>Fehler:</b> Verbindung zur Kamera nicht möglich. Bitte überprüfen Sie Ihre Browser Einstellungen und gewähren den Zugriff auf die Kamera.</Typography>}
                        <Webcam className={mediaError ? classes.mediaError : (classes.image)}
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            imageSmoothing={true}
                            screenshotQuality={1}
                            videoConstraints={videoConstraints}
                            onUserMediaError={handleUserMediaError}
                            mirrored="true"
                            hidden={captured}
                          

                        />
                        {imgSrc && <img src={imgSrc} className={classes.image} />}
                </Paper>
                <Grid container direction={"row"} justify={"center"} spacing={2} style={{ marginTop: 50 }}>
                    <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                        <Button variant="contained" color="primary" size="large" 
                            onClick={toggleCapture} >{captured ? "Selfie wiederholen" : "Selfie aufnehmen"}</Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                        <Button variant="contained" color="primary" size="large"  disabled={!captured} onClick={() => setDialogOpen({...dialogOpen, consentDialog: true})}
                        >Selfie übermitteln</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        {error && <Dialog isOpen={dialogOpen.errorDialog} cancelLabel={errorDialog.cancelLabel} cancelOnClick={() => setDialogOpen({...dialogOpen, errorDialog: false})} title={errorDialog.title} content={errorDialog.content}  />}
        {dialogOpen.consentDialog && <Dialog isLoading={loading} isOpen={dialogOpen.consentDialog} cancelLabel={consentDialog.cancelLabel} okLabel={consentDialog.okLabel} title={consentDialog.title} content={consentDialog.content} okOnClick={() => setConsent(true)}  cancelOnClick={() => setDialogOpen({...dialogOpen, consentDialog: false})} /> }


    </>

}






