import Button from "@material-ui/core/Button"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from "react"
import LinearProgress from '@material-ui/core/LinearProgress';

export default function MyDialog({ title, content, cancelLabel, okLabel, cancelOnClick, okOnClick, isOpen, isLoading }) {

    return <>
        <Dialog open={isOpen} onClose={cancelOnClick} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelOnClick} color="secondary">
                    {cancelLabel}
                </Button>
                {okLabel && <Button type="submit" color="secondary" disabled={isLoading} onClick={okOnClick} >
                    {okLabel}
                </Button>}
            </DialogActions>
            {isLoading &&<LinearProgress color="secondary" />}
        </Dialog>
    </>

}






