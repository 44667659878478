import { gql, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import Loading from './Loading'


export default function Guardien({gql, variables, children,  linkError, pollInterval}) {
  const { loading, error, data } = useQuery(gql, {
    variables,
    pollInterval
  });


if(loading){
  return (<Loading />)

}

if(error){
  if(error.graphQLErrors){
    return (<Redirect to={linkError}/>)
  }
}

if(data){
     return <> {children} </>
  }


  return null
}

